import * as actionTypes from './actions';
import config from './../config';

const initialState = {
    isOpen: [],
    isTrigger: [],
    ...config,
    isFullScreen: false,
    isLoading: false,
    isAuthenticated: false,
    company: null,
    user: null,
    toast: {
        show: false,
        variant: null,
        message: ""
    },
    skipAuth: false
};

const reducer = (state = initialState, action) => {
    let trigger = [];
    let open = [];

    switch (action.type) {
        case actionTypes.COLLAPSE_MENU:
            return {
                ...state,
                collapseMenu: !state.collapseMenu
            };
        case actionTypes.COLLAPSE_TOGGLE:
            if (action.menu.type === 'sub') {
                open = state.isOpen;
                trigger = state.isTrigger;

                const triggerIndex = trigger.indexOf(action.menu.id);
                if (triggerIndex > -1) {
                    open = open.filter(item => item !== action.menu.id);
                    trigger = trigger.filter(item => item !== action.menu.id);
                }

                if (triggerIndex === -1) {
                    open = [...open, action.menu.id];
                    trigger = [...trigger, action.menu.id];
                }
            } else {
                open = state.isOpen;
                const triggerIndex = (state.isTrigger).indexOf(action.menu.id);
                trigger = (triggerIndex === -1) ? [action.menu.id] : [];
                open = (triggerIndex === -1) ? [action.menu.id] : [];
            }

            return {
                ...state,
                isOpen: open,
                isTrigger: trigger
            };
        case actionTypes.NAV_CONTENT_LEAVE:
            return {
                ...state,
                isOpen: open,
                isTrigger: trigger,
            };
        case actionTypes.NAV_COLLAPSE_LEAVE:
            if (action.menu.type === 'sub') {
                open = state.isOpen;
                trigger = state.isTrigger;

                const triggerIndex = trigger.indexOf(action.menu.id);
                if (triggerIndex > -1) {
                    open = open.filter(item => item !== action.menu.id);
                    trigger = trigger.filter(item => item !== action.menu.id);
                }
                return {
                    ...state,
                    isOpen: open,
                    isTrigger: trigger,
                };
            }
            return {...state};
        case actionTypes.FULL_SCREEN :
            return {
                ...state,
                isFullScreen: !state.isFullScreen
            };
        case actionTypes.FULL_SCREEN_EXIT:
            return {
                ...state,
                isFullScreen: false
            };
        case actionTypes.CHANGE_LAYOUT:
            return {
                ...state,
                layout: action.layout
            };
        case actionTypes.SET_COMPANY:
            return {
                ...state,
                company: action.payload
            };
        case actionTypes.UNSET_COMPANY:
            return {
                ...state,
                company: null
            };
        case actionTypes.LOGIN:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload
            };
        case actionTypes.LOGOUT:
            return {
                ...state,
                isAuthenticated: false,
                user: null
            };
        case actionTypes.UPDATE_PROFILE:
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload
                }
            };
        case actionTypes.SHOW_TOAST:
            return {
                ...state,
                toast: {
                    show: true,
                    message: action.payload.message,
                    variant: action.payload.variant
                }
            };
        case actionTypes.HIDE_TOAST:
            return {
                ...state,
                toast: {
                    show: false,
                    message: "",
                    variant: null
                }
            };
        case actionTypes.SKIP_AUTHENTICATION:
            return {
                ...state,
                skipAuth: action.payload
            };
        default:
            return state;
    }
};

export default reducer;