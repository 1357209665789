/* eslint-disable */

import React, { Component, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import Loadable from 'react-loadable';

import '../../node_modules/font-awesome/scss/font-awesome.scss';

import Loader from './components/Loader';
import ScrollToTop from './components/ScrollToTop';

import Aux from "../hoc/_Aux";
import AuthRoute from "../hoc/_Auth";
import authRoutes from '../authRoutes';

const Layout = Loadable({
    loader: () => import('./layout/default'),
    loading: Loader
});

class App extends Component {
    render() {
        const menu = authRoutes.map((route, index) => {
          return (route.component) ? (
              <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={props => (
                      <route.component {...props} />
                  )} />
          ) : (null);
        });

        return (
            <Aux>
                <ScrollToTop>
                    <Suspense fallback={<Loader/>}>
                        <Switch>
                            {menu}
                            <AuthRoute path="/" component={Layout} />
                        </Switch>
                    </Suspense>
                </ScrollToTop>
            </Aux>
        );
    }
}

export default App;