import React from 'react';

const Signin = React.lazy(() => import('./App/pages/signin'));
const Login = React.lazy(() => import('./App/pages/auth/login'));
const SignUp = React.lazy(() => import('./App/pages/auth/signup'));
const ResetPassword = React.lazy(() => import('./App/pages/auth/reset'));
const Logout = React.lazy(() => import('./App/pages/auth/logout'));

const route = [
    { path: '/signup', exact: true, name: 'SignUp', component: SignUp },
    { path: '/signin', exact: true, name: 'Signin', component: Signin },
    { path: '/login', exact: true, name: 'Login', component: Login },
    { path: '/reset-password', exact: true, name: 'ResetPassword', component: ResetPassword },
    { path: '/auth/logout', exact: true, name: 'Logout', component: Logout, authRequired: false }
];

export default route;